export class ContactAddress {
  constructor (info) {
    this.firstName = info.firstName
    this.lastName = info.lastName
    this.addressLine1 = info.addressLine1
    this.addressLine2 = info.addressLine2
    this.email = info.email
    this.tel = info.tel
    this.longitude = info.longitude
    this.latitude = info.latitude
    this.plz = info.plz
    this.type = info.type
    this.city = info.city
  }

  hash () {
    return Object.values(this).reduce((str, i) => {
      return str + i
    }, "")
  }
}

function hash (obj) {
  return Object.values(obj).reduce((str, i) => {
    return str + i
  }, "")
}

const storageKey = 'aaden-address-list'

/**
 *
 * @returns {ContactAddress[]}
 */
export function getCurrentAddressList (type) {
  const item = localStorage.getItem(storageKey + '-' + type)
  return (item ? JSON.parse(item) : [])
}

/**
 *
 * @param {ContactAddress} address
 * @param type
 * @returns {ContactAddress[]}
 */
export function saveAddress (address, type) {
  const list = getCurrentAddressList(type)
  if (!list.find(it => hash(it) === hash(address))) {
    list.push(address)
  }

  localStorage.setItem(storageKey + '-' + type, JSON.stringify(list))
  return getCurrentAddressList(type)
}

