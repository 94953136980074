<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" v-model="realValue" persistent

  >
    <v-card tile

            :class="$vuetify.breakpoint.smAndDown?'d-flex flex-column justify-center':''"
            :width="$vuetify.breakpoint.smAndDown?'auto':'500px'">
      <div class="pa-4">
        <div v-if="step===0">
          <div class="text-subtitle-1 font-weight-bold">{{ $t('deliveryOrPickup') }}</div>
          <div class="mt-4" style="display: grid;grid-template-columns: repeat(2,minmax(0,1fr));grid-gap: 12px">
            <v-card @click="reselectDeliveryMethod(DeliveryMethod.delivery)" flat color="#eee">
              <v-responsive :aspect-ratio="1">
                <div style="height: 100%" class="d-flex align-center justify-center flex-column">
                  <v-icon size="64">mdi-truck-fast</v-icon>
                  <div class="mt-4 text-body-1">{{ $t(DeliveryMethod.delivery) }}</div>
                </div>
              </v-responsive>
            </v-card>
            <v-card @click="reselectDeliveryMethod(DeliveryMethod.pickup)" flat color="#eee">
              <v-responsive :aspect-ratio="1">
                <div style="height: 100%" class="d-flex align-center justify-center flex-column">
                  <v-icon size="64">mdi-walk</v-icon>
                  <div class="mt-4 text-body-1">{{ $t(DeliveryMethod.pickup) }}</div>
                </div>
              </v-responsive>

            </v-card>
          </div>
          <div class="text-body-1 mt-4">
            <v-btn text small @click="realValue=false">{{ $t('notNow') }}</v-btn>
          </div>
        </div>
        <div v-else-if="step===1">
          <div class="d-flex align-center">
            <div class="text-subtitle-1 font-weight-bold">
              {{ isPickUp ? $t('pls_choose_a_contact') : $t('pls_choose_an_address') }}
            </div>
          </div>

          <v-list class="mt-4">
            <template v-for="a in currentAddressList">
              <v-list-item :disabled="!(isPickUp||a.deliveryRule.canDelivery)" @click="pop(a)"
                           :key="a.firstName+a.lastName+a.addressLine1+a.city">
                <v-list-item-content class="darken-4">
                  <v-list-item-title>{{ a.firstName }} {{ a.lastName }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ isPickUp ? a.tel + '/' + a.email : a.addressLine1 + ', ' + a.city }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="!isPickUp">
                    {{ a.deliveryRule.deliveryDistance.toFixed(2) }}km
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon>{{ isPickUp ? 'mdi-account' : 'mdi-map-marker' }}</v-icon>
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="a.firstName+a.lastName+a.addressLine1+'d'+a.city"></v-divider>
            </template>
            <v-list-item @click="showAddNewAddressDialog=true" :class="noAddressNow?'primary--text':''">
              <v-list-item-content class="darken-4">
                <v-list-item-title>{{ $t('add_an_address') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon :color="noAddressNow?'primary':''">mdi-plus</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="reset">
              <v-list-item-content class="darken-4">
                <v-list-item-title>{{ $t('return') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>mdi-arrow-left</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </div>
        <add-new-address-dialog
            @address-save="saveAndReload"
            :type="deliveryMethod"
            v-model="showAddNewAddressDialog"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { DeliveryMethod } from '@/dataLayer/service/OrderService'
import { getCurrentAddressList, saveAddress } from '@/dataLayer/repository/localAddressInfo'
import AddNewAddressDialog from '@/pages/widget/adress/AddNewAddressDialog'
import { calculateDeliveryRule } from '@/dataLayer/service/api'
import GlobalConfig from '@/dataLayer/repository/GlobalSettingManager'


export default {
  name: "AddressSelector",
  components: {AddNewAddressDialog},
  props: {
    value: {}
  },
  data: function () {
    return {
      step: 0,
      currentAddressList: [],
      showAddNewAddressDialog: false,
      deliveryMethod: DeliveryMethod.delivery,
      DeliveryMethod,
      realValue: null

    }
  },
  watch: {
    value: {
      immediate: true,
      handler (val) {
        this.realValue = val
        if (val) {
          this.reset()
        }
      }
    },
    realValue (val, old) {
      if (val !== old) {
        this.$emit('input', val)
      }
    }
  },

  computed: {
    noAddressNow () {
      return this.currentAddressList.length === 0
    },

    realShow: {
      get: function () {
        return this.show
      },
      set: function () {
        this.cancel()
      }
    },
    isPickUp () {
      return this.deliveryMethod === DeliveryMethod.pickup
    }
  },
  methods: {
    async saveAndReload (address) {
      saveAddress(address, this.deliveryMethod)
      await this.loadAddress(this.deliveryMethod)
      this.showAddNewAddressDialog = false
    },
    async reselectDeliveryMethod (v) {
      this.deliveryMethod = v
      await this.loadAddress(v)
      this.step++
    },
    async loadAddress (type) {
      const list = getCurrentAddressList(type)
      if (!this.isPickUp) {
        for (const a of list) {
          a.deliveryRule = await calculateDeliveryRule(a)
        }
      }
      this.currentAddressList = list
    },
    pop (d) {
      const data = Object.assign({}, {deliveryMethod: this.deliveryMethod}, d)
      this.$emit('save', data)
    },


    reset () {
      this.step = 0
      if (GlobalConfig.hideDelivery) {
        this.reselectDeliveryMethod(DeliveryMethod.pickup)
      }

    }
  }
}
</script>

<style scoped>
.header {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  min-height: 3rem;
  padding: 0px 4.5rem;
  text-align: center;
  margin: 0px;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


</style>
