import firebase from 'firebase/compat'
import { collection, query, getDocs } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyATTtRB1PlOC-At0-ndKqap4RABwwCc6ew',
  authDomain: 'aaden-boss-test.firebaseapp.com',
  projectId: 'aaden-boss-test',
  storageBucket: 'aaden-boss-test.appspot.com',
  messagingSenderId: '604566804841',
  appId: '1:604566804841:web:a82cb7788517358ceb45f8',
  measurementId: 'G-CST41XTVMJ'
}
const websiteUrl = 'websiteUrls'
export const FireBaseApp = firebase.initializeApp(firebaseConfig)
export const FireBaseStore = firebase.firestore()
const collectionRef = collection(FireBaseStore, websiteUrl)

export async function showRecords () {
  const res = query(collectionRef)
  return (await getDocs(res)).docs.map(it => it.data())
}
