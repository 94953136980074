<template>
  <div>
    <v-lazy v-if="dish.count !== 0" min-height="120px">

      <div class="dishItem">
        <div class="enhanceDisplay"></div>
        <div class="dishLabel">
          <div class="dishName pl-2">
            {{ dish.code }}. {{ dish.dishName }}
            <span v-if="dish.addPrice !== 0">*</span>
          </div>
          <div class="dishDesc pl-3">
            {{ dishModStr }}
          </div>
          <div class="dishPrice pl-3">{{ dish.realPrice | priceDisplay }} €</div>
          <div class="d-flex align-center">
            <v-btn @click="dish.count>0?dish.count--:null" icon>
              <v-icon color="#009de0" small>mdi-minus</v-icon>
            </v-btn>
            <span class="dishPrice">{{ dish.count }}</span>
            <v-btn icon @click="dish.count++">
              <v-icon color="#009de0" small>mdi-plus</v-icon>
            </v-btn>
          </div>
        </div>
        <v-card style="border-radius: 8px" class="dishImage"
                v-if="dish.image&&dish.image.toLowerCase().endsWith('.png')
                ||dish.image.toLowerCase().endsWith('.jpg')
                ||dish.image.toLowerCase().endsWith('.jpeg')">
          <v-img height="80" width="120"
                 :src="imageRoot+dish.image"></v-img>
        </v-card>
      </div>
    </v-lazy>
    <v-divider></v-divider>
  </div>
</template>

<script>
export default {
  name: "EditDishCard",
  props: {
    imageRoot: {},
    dish: {}
  },
  computed: {
    dishModStr: function () {
      return this.dish.displayApply.map((i) => i.groupName + ':' + i.value).join(', ')
    }
  },
  components: {}
}
</script>

<style scoped>

</style>
