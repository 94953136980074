<template>
  <v-dialog v-model="realValue" width="500px">
    <v-card class="contentPadding">
      <div class="text-subtitle-1">
        {{ isPickUp ? $t('Bitte Kontakt Info eingaben') : $t('Ein neue Adresse hinzufügen.') }}
      </div>

      <div class="mt-4">
        <template v-if="step===0">
          <div class="contentPadding">
            <v-text-field
                :rules="addressRules"
                autofocus v-model="addressInput"
                @update:error="errorUpdate"
                :label="$t('plsEnterTheAddress')"
                placeholder="...(LepigStr.156 Berlin)..."
            ></v-text-field>
          </div>
          <template v-if="targetAddress.length>0">
            <v-subheader class="mt-n8">{{ $t('plsSelectFromAddressList') }}</v-subheader>
            <v-list-item style="overflow-x: hidden"
                         :key="line.place_id"
                         @click="usePlaceId(line.place_id)"
                         v-for="line in targetAddress">
              <v-list-item-icon>
                <v-icon>mdi-map-marker</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ line.description }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </template>
        <template v-else-if="step===1">
          <div>
            <template v-if="!isPickUp&&currentAddressData">
              <div v-if="!isPickUp" class="d-flex align-center">
                <div class="caption">{{ $t('deliveryTo:') }}</div>
                <v-spacer></v-spacer>
                <div>
                  {{ currentAddressData.addressLine1 }}, {{ currentAddressData.city }}
                </div>
                <v-btn icon
                       @click="reset"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <v-text-field
                  readonly
                  :value="currentAddressData.addressLine1"
                  :label="$t('addressLine1')"
                  hide-details/>
              <v-text-field
                  v-model="addressLine2"
                  :label="$t('Zusätzliche Address.')"
                  hide-details/>
            </template>


            <div style="display: grid;grid-template-columns: repeat(2,1fr);grid-gap: 8px">
              <v-text-field v-model="firstName" :label="$t('firstName')" hide-details/>
              <v-text-field v-model="lastName" :label="$t('lastName')" hide-details/>
            </div>
            <v-text-field v-model="phone" :label="$t('phone')" hide-details/>
            <v-text-field v-model="email" :label="$t('email')" hide-details/>

            <v-btn @click="saveAddress" :disabled="!addressIsValid" elevation="0" block x-large
                   class="mainButton mt-3"
                   style="border-radius: 12px">
              <span
                  style="font-size: 14px !important;text-transform: capitalize;font-weight: 500;letter-spacing: 0;color: white;">{{
                  $t('Save Address')
                }}</span>
            </v-btn>
          </div>
        </template>
      </div>

    </v-card>
  </v-dialog>

</template>

<script>
import { DeliveryMethod } from '@/dataLayer/service/OrderService'
import { getDetailData, getPredictions } from '@/dataLayer/service/GoogleMapService'
import { ContactAddress } from '@/dataLayer/repository/localAddressInfo'

export default {
  name: "AddNewAddressDialog",
  props: {value: {}, type: {}},
  watch: {
    value: {
      immediate: true,
      handler (val) {
        this.realValue = val
        if (val) {
          this.reset()
        }
      }
    },
    realValue (val, old) {
      if (val !== old) {
        this.$emit('input', val)
      }
    },
    async inputIsOk (val) {
      if (val) {
        this.targetAddress = await getPredictions(this.addressInput)
      }
    },
    async addressInput (val) {
      if (val?.length > 4 && this.inputIsOk) {
        this.targetAddress = await getPredictions(val)
      }
    }
  },
  computed: {
    addressIsValid () {
      return this.phone && this.email && this.firstName && this.lastName
    },
    isPickUp () {
      return this.type === DeliveryMethod.pickup
    }
  },
  data: function () {
    return {
      step: 0,
      showAddNewAddressButton: false,
      targetAddress: [],
      addressInput: '',
      realValue: null,
      phone: '',
      email: '',
      firstName: '',
      lastName: '',
      addressLine2: '',
      currentAddressData: null,
      showAddressForm: false,
      inputIsOk: false,

      addressRules: [
        v => /^.*[0-9]{1,3}/.test(v) || this.$t('Bitte tippen Sie das Straßennummer nach den Straßenname ein, und wählen')
      ]

    }
  },
  methods: {
    saveAddress () {
      console.log(this.currentAddressData)
      const oldData = Object.assign({}, this.currentAddressData, {
        tel: this.phone,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        addressLine2: this.addressLine2,
        type: this.type
      })

      const data = new ContactAddress(oldData
      )
      this.$emit('address-save', data)
      console.log(data)

    },
    reset () {
      this.currentAddressData = null
      this.addressInput = ''
      this.targetAddress = []
      if (this.isPickUp) {
        this.step = 1
      } else {
        this.step = 0
      }
    },
    async usePlaceId (placeId) {
      this.currentAddressData = await getDetailData(placeId)
      console.log(this.currentAddressData)
      this.step = 1
    },
    errorUpdate (e) {
      this.inputIsOk = !e
    }
  }
}
</script>

<style scoped>

</style>
