<template>
  <div style="width: 100%" class="fill-height">
    <v-card color="grey" style="width: 100%;height: 48px"></v-card>
    <div class="fill-height d-flex flex-wrap justify-center">
      <div style="width: 100%" class="d-flex justify-center">
        <lottie-animation v-cloak
                          ref="animation"
                          :autoPlay="true"
                          :animationData="require('@/assets/completed.json')"></lottie-animation>
      </div>
      <v-fade-transition>
        <div class="pa-4"  v-if="show">
          <h1>OK!👌</h1>
          <p>{{ $t('Wir haben Ihre Bestellung erhalten! Und eine Bestätigungs-E-Mail wird an das von Ihnen ausgefüllte Postfach gesendet, wenn sich danach Änderungen ergeben, benachrichtigen wir Sie per E-Mail!') }}</p>
          <p>Made with ❤️ &nbsp;@InnerKen</p>
          <v-btn text color="#009de0" @click="$router.push('/')"> {{ $t('return') }} </v-btn>
        </div>
      </v-fade-transition>
    </div>
  </div>
</template>

<script>
import LottieAnimation from 'lottie-web-vue' // import lottie-web-vue


export default {
  components: {LottieAnimation},
  name: "OrderComplete",
  data: function () {
    return {show: false};
  },
  mounted() {
    setTimeout(() => {
      this.show = true
    }, 1000)
  }
}
</script>

<style scoped>

</style>
