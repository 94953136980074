<template>
  <div>
    <v-btn v-if="differentPrice >= 0" tile block dark color="#009de0"
           @click="$router.push('/checkout')">
      {{ totalPrice | priceDisplay }} € {{ $t('Zu Kasse') }}
    </v-btn>
    <v-btn v-else-if="!isPickUp && differentPrice < 0" disabled tile block color="#009de0"
           @click="$router.push('/checkout')">
      {{ $t('Start price is') }} {{ minimumDeliveryFee | priceDisplay }} €, {{ $t('Bitte noch') }} {{ -differentPrice | priceDisplay }} €.
    </v-btn>
    <v-btn v-else-if="isPickUp" tile block dark color="#009de0"
           @click="$router.push('/checkout')">
      {{ totalPrice | priceDisplay }} € {{ $t('Zu Kasse') }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "CartButton",
  props: {
    isPickUp: { type: Boolean },
    differentPrice: { type: Number },
    totalPrice: { type: Number },
    minimumDeliveryFee: { },
    todayOpenTimeDisplay: { type: String },
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
