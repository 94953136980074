const gService = new window.google.maps.places.AutocompleteService()
const placeService = new window.google.maps.places.PlacesService(document.createElement('div'))


export async function getPredictions (input) {
  return new Promise(((resolve, reject) => {
    gService.getPlacePredictions({
      componentRestrictions: {country: 'DE'},
      input
    }, (predictions, status) => {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        reject('Not OK')
      } else {
        resolve(predictions)
      }
    })
  }))
}

function findInAddressComponents (address, key) {
  // eslint-disable-next-line camelcase
  return (address.find(f => f.types.includes(key)))?.long_name
}

async function fromAddressDataToAddressObject (addressData) {
  if (addressData) {
    const address = {
      addressLine1: addressData.name,
      plz: findInAddressComponents(addressData.address_components, 'postal_code'),
      city: findInAddressComponents(addressData.address_components, 'locality'),
      country: findInAddressComponents(addressData.address_components, 'country'),
      houseNo: findInAddressComponents(addressData.address_components, 'street_number'),
      latitude: addressData.geometry.location.lat(),
      longitude: addressData.geometry.location.lng()
    }
    address.titleAddress = address.addressLine1 + ', ' + address.plz + ' ' + address.city + ', ' + address.country
    return address
  }
  return null
}

export async function getDetailData (placeId) {
  return new Promise(((resolve) => {
    placeService.getDetails({
      placeId,
      fields: ['address_components', 'geometry', 'name']
    }, (addressData) => {
      resolve(fromAddressDataToAddressObject(addressData))
    })
  }))
}

export function init () {

}
