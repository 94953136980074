<template>
  <div id="app">
    <v-app>
      <v-app-bar color="transparent" dense flat absolute dark v-if="$vuetify.breakpoint.smAndDown">

        <template v-if="GlobalConfig.shopHomepageUrl&&$route.name!=='Checkout'">
          <v-btn text>
            <v-icon left size="24">mdi-home</v-icon>
            <div class="ml-3 font-weight-bold d-flex align-center">
              <a :href="GlobalConfig.shopHomepageUrl"
                 style="text-decoration: none; color: white">{{ $t('homePage') }}</a></div>
            <v-spacer/>
          </v-btn>
        </template>
        <template v-else>
          <div class="d-flex align-center fill-height">
            <v-icon small>mdi-moped</v-icon>
            <div style="text-align: left" class="ml-2">
              <div style="font-size: xx-small;line-height: 9px">Aaden</div>
              <div style="font-size: small;font-weight: bold;line-height: 16px">Knoten</div>
            </div>
          </div>
        </template>
        <v-spacer></v-spacer>
        <toolbar-language/>
      </v-app-bar>
      <v-main app>
        <router-view></router-view>
      </v-main>

    </v-app>
  </div>
</template>
<script>

import ToolbarLanguage from './components/ToolbarLanguage'
import GlobalConfig from "@/dataLayer/repository/GlobalSettingManager"

export default {
  name: 'App',
  components: {ToolbarLanguage},
  data() {
    return {
      GlobalConfig
    }
  },

}
</script>

<style>

.dishImage {
  border-radius: 16px;
  width: 120px;
  height: 80px;
  margin-left: 12px;
}

.dishLabel {
  flex-grow: 0;
}

.dishPrice {
  padding-left: 4px;
  font-size: 0.9rem;
  color: #009de0;
}

.fakeButton:hover {
  cursor: pointer;
}

.dishDesc {
  padding-left: 5px;
  color: rgba(32, 33, 37, 0.64);
  position: relative;
  font-size: .75rem;
  line-height: 1.4;
  max-height: 4.2em;
  margin-bottom: 0.375rem;
  letter-spacing: -.15px;
  white-space: pre-line;
  overflow: hidden;
}

.dishDescDetail {
  padding-left: 5px;
  color: rgba(32, 33, 37, 0.64);
  position: relative;
  font-size: .75rem;
  line-height: 1.4;
  margin-bottom: 0.375rem;
  letter-spacing: -.15px;
  white-space: pre-line;
}


.dishName {
  padding: 0;
  font-size: 0.98rem;
  line-height: 1.4;
  max-height: 2.8em;
  font-weight: 600;
  margin-bottom: 0.375rem;
  white-space: pre-wrap;
  overflow: hidden;
  max-width: calc(100vw - 32px);
  text-overflow: ellipsis;
}

.enhanceDisplay {
  position: absolute;
  background: #009de0;
  top: 1.5rem;
  left: 0;
  height: 100px;
  border-radius: 4px;
  width: 4px;
  transform-origin: 0%;
  will-change: transform;
}

.dishItem {
  min-width: 300px;
  padding: 1.5rem 16px;
  justify-content: space-between;
  border: 1px solid transparent;
  border-radius: 2px;
  display: flex;
  position: relative;
  transition: 0.3s;
}

.checkOutPageButton {
  position: fixed;
  bottom: 16px;
  width: 90vw;
  height: 120px;
  z-index: 100;
  max-width: 1150px;
}

.dishItem:hover {
  border-radius: 2px;
  cursor: pointer;
  border: 1px solid #bebebe;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.addMoreItem {
  min-width: 300px;
  padding: 1.5rem 16px;
  border: 1px solid transparent;
  border-radius: 2px;
  transition: 0.3s;
}

.addMoreItem:hover {
  border-radius: 2px;
  cursor: pointer;
  border: 1px solid #bebebe;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}


.categoryDesc {
  font-size: 0.9rem;
  color: rgba(32, 33, 37, 0.64);
  font-weight: 400;
  margin-top: 16px;
  white-space: pre-wrap;
}

.itemContainer {
  width: 100%;
  overflow: hidden;
}

.categoryContainer {
  margin: 1.5rem 16px
}

.infoButton {
  background: #ebf7fd !important;
  color: #009de0 !important;
  text-transform: capitalize;
}

.infoButton:hover {
  background: #ccebf9;
}

.listItem {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0.5rem 0;
}

.mainButton {
  background: rgb(0, 157, 224) !important;
}

.contentPadding {
  padding: 24px 16px;
}

.expandContent:hover {
  cursor: pointer;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

::-webkit-scrollbar-thumb:horizontal {
  width: 5px;
  background-color: #CCCCCC;
  -webkit-border-radius: 6px;
}

::-webkit-scrollbar-track-piece {
  background-color: #fff;
  -webkit-border-radius: 0;
}

::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

::-webkit-scrollbar-thumb:vertical {
  height: 50px;
  background-color: #999;
  -webkit-border-radius: 4px;
  outline: 2px solid #fff;
  outline-offset: -2px;
  border: 2px solid #fff;
}

::-webkit-scrollbar-thumb:hover {
  height: 50px;
  background-color: #9f9f9f;
  -webkit-border-radius: 4px;
}

</style>
<style lang="scss">
#app {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Roboto", "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: medium;
}

textarea {
  font-size: 10px;
}
</style>
