import { getRestaurantInfo } from '@/dataLayer/service/api'
import i18n from '@/i18n'

export const defaultRestaurantInfo = {
  adress1: '',
  adress2: '',
  city: '',
  name: '',
  postCode: '',
  currentlyOpening: 0,
  emailAddress: '',
  inhalterLastName: '',
  inhalterName: '',
  latitude: '',
  longitude: '',
  state: '',
  takeawayPriceModification: 0,
  taxNumber: '',
  telephone: ''
}


let RestaurantInfo = null

export async function getCurrentRestaurantInfo () {
  if (RestaurantInfo == null) {
    RestaurantInfo = Object.assign({}, defaultRestaurantInfo, (await getRestaurantInfo(i18n.locale, 'restaurantInfo'))[0])

    const reg = new RegExp('<BR>', 'g')
    RestaurantInfo.name = RestaurantInfo.name.replace(reg, ' ').replace('-', '‧')
    //这里修正了 currentlyOpening 始终为1
    RestaurantInfo.currentlyOpening = parseInt(RestaurantInfo.currentlyOpening) ?? 1
    // RestaurantInfo.currentlyOpening =  1
  }
  return RestaurantInfo
}
